body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #eee;
  color: #333;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 10px;
}
.App {
  font-family: Lato, sans-serif;
}

.App-logo {
  height: 40px;
}

.App-header {
  background-color: #fff;
  height: 40px;
  padding: 20px;
}
.App-intro {
  font-size: large;
}

.card {
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  margin-top: -2px;
}
.card .row {
  display: flex;
  justify-content: space-between;
}
.card .row.left {
  justify-content: flex-start;
}
.card .row.left > * {
  margin-right: 15px;
}

.card .small {
  font-size: 11px;
  margin: 5px 0;
  opacity: 0.6;
}
.card p {
  font-size: 14px;
  margin: 5px 0;
}
input,
textarea {
  display: block;
  padding: 10px;
  font-size: 15px;
  width: 250px;
  background: #fff;
  border: 1px solid #e5e5e5;
  margin: 5px 0;
  outline: none;
  resize: vertical;
}

input:read-only {
  background: #eee;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  width: 100%;
}

button,
.button {
  padding: 10px;
  font-size: 15px;
  background: #fff;
  border: 1px solid #e5e5e5;
  margin: 5px 0;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}

h4 {
  margin: 5px 0;
}
